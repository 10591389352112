import { Cloud$Country } from '../../../types/cloudEnums';

export const COUNTRY_CALLING_CODES: Record<Cloud$Country, number> = {
  no: 47,
  se: 46,
  fr: 33,
  es: 34,
  br: 55,
  pl: 48,
  it: 39,
  de: 49,
  at: 43,
  pt: 351,
  gb: 44,
  be: 32,
  nl: 31,
  ch: 41,
};
