import { useSelect } from 'downshift';
import Image from 'next/image';
import { FormattedMessage as T } from 'react-intl';
import { cn } from '../../../lib/classNames';
import useMobileVersion from '../../../lib/useMobileVersion';
import { Cloud$Market } from '../../../types/cloudEnums';
import { getBackgroundColorForDropdown } from '../dropdownColorUtil';
import m from '../messages';
import './flags.css';

type MarketList = Cloud$Market[];

type Props = {
  displayItem: (market: Cloud$Market) => React.ReactNode;
  items: MarketList;
  itemToString: (market: Cloud$Market) => string;
  onChange: (market: Cloud$Market) => void;
  selectedItem: Cloud$Market;
};

const MarketDropdownSelect = ({
  displayItem,
  items,
  itemToString,
  onChange,
  selectedItem,
}: Props) => {
  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items,
    onSelectedItemChange: (change) => {
      onChange(change.selectedItem);
    },
    selectedItem,
    itemToString,
  });
  const isMobileVersion = useMobileVersion(800);

  return (
    <>
      {/* The getLabelProps() will automatically set for="" attribute for the label, so let's disable ESLint */}
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="sr-only" {...getLabelProps()}>
        <T {...m.selectMarket} />
      </label>
      <button
        className="flex items-center rounded border-none bg-grey-5 p-2 text-lg"
        type="button"
        {...getToggleButtonProps()}
      >
        <Image
          alt="Flag"
          width={44}
          height={30}
          className={`flag flag-${selectedItem} h-auto w-6 border border-solid border-grey-10`}
          src="/img/flag_placeholder.png"
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24px"
          height="24px"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M12 14l-4-4h8z" />
        </svg>
      </button>
      <ul
        className={cn(
          'absolute z-10 mt-1 max-h-48 w-3/6 list-none overflow-y-scroll rounded bg-grey-60 pl-0',
          isOpen ? 'border border-solid' : 'border-none',
        )}
        {...getMenuProps()}
      >
        {isOpen &&
          items.map((item, index) => (
            <li
              className={cn(
                'p-2 hover:cursor-pointer',
                getBackgroundColorForDropdown(
                  items,
                  selectedItem,
                  highlightedIndex,
                  index,
                ),
              )}
              key={item}
              {...getItemProps({ item, index })}
            >
              <img
                alt="Flag"
                width={44}
                height={30}
                className={`flag mr-2 h-auto w-6 align-middle flag-${item} inline-block`}
                src="/img/flag_placeholder.png"
              />
              <p className="inline-block align-middle text-base leading-3 text-blue-10">
                {!isMobileVersion && displayItem(item)}
              </p>
            </li>
          ))}
      </ul>
    </>
  );
};

export default MarketDropdownSelect;
